import React, {FC, memo, useCallback, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Formik} from 'formik';
import zxcvbn from 'zxcvbn';
import classNames from 'classnames';
import Button from "../../../../shared/components/Button/Button";
import Input from "../../../../shared/components/Input/Input";
import InputField from "../../../../shared/components/InputField/InputField";
import PasswordStrengthMeter from "../../../../shared/components/PasswordStrengthMeter/PasswordStrengthMeter";

import {registerUser} from 'redux/features/user';
import {RootState} from 'redux/store';
import {setErrorMessage} from 'pages/RegisterUser/redux';
import cookie from 'js-cookie';
import size from 'lodash/size';
import styles from './RegisterForm.module.scss';

// interface Props {
//     inviteToken: string;
//     email: string;
// }
interface RegisterFormProps {
    currentWizardStep: number;
    onWizardChange: (value: number) => void;
}

const RegisterFormComponent: React.FC<RegisterFormProps> = ({currentWizardStep,onWizardChange}) => {
    const initialFormValues = {
        information: {
            first_name: '',
            last_name: '',
        },
        password: '',
        password_again: '',
    };

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [passwordToValidate, setpasswordToValidate] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [showPass, setShowPass] = useState(!!cookie.get('provider') && cookie.get('provider') === 'xelacore' || true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isNextStepDisabled, setIsNextStepDisabled] = useState(true);

    const dispatch = useDispatch();
    const errorMessage = useSelector<RootState, string | undefined>((state) => state.registerUserPage.errorMessage);


    useEffect(() => {
        if (firstName.trim() === '' || lastName.trim() === '') {
            setIsNextStepDisabled(true);
        } else {
            setIsNextStepDisabled(false);
        }
        onWizardChange(currentWizardStep)
    }, [firstName, lastName, onWizardChange, currentWizardStep]);


    const onFormValueSubmit = useCallback((formValue) => {
        if (!size(formValue.information.first_name) || !size(formValue.information.last_name)) {
            dispatch(setErrorMessage('First and last name are mandatory fields'));
            return;
        }

        const registerUserEntity = {...formValue};
        delete registerUserEntity.password_again;

        if (!showPass) {
            delete registerUserEntity.password;
        }

        dispatch(registerUser({registerUserEntity}));
    }, []);

    const checkTermsAccepted = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAccepted(e.target.checked)
    }

    const handleFormChange = (event: any) => {
        const {name, value} = event.target;

        if (name === 'information.first_name') {
            setFirstName(value ?? '')
        }
        if (name === 'information.last_name') {
            setLastName(value ?? '')
        }
        if (name === 'password') {
            setpasswordToValidate(value);
        }
        if (name === 'password_again') {
            setconfirmPassword(value);
        }
    }

    return (
        <Formik enableReinitialize
                initialValues={initialFormValues}
                onSubmit={(values) => onFormValueSubmit(values)}>
            {() => (
                <Form className={styles.root} onChange={(e) => handleFormChange(e)}>
                    {currentWizardStep === 1 && (
                        <div>
                            <InputField fieldName="First Name" newStyle={false}>
                                <Input name="information.first_name"></Input>
                            </InputField>
                            <InputField fieldName="Last Name" newStyle={false}>
                                <Input type="text" name="information.last_name"></Input>
                            </InputField>
                            {showPass && (
                                <>
                                    <InputField fieldName="Password" newStyle={false}>
                                        <>
                                            <Input type="password" name="password"></Input>
                                            <PasswordStrengthMeter
                                                password={passwordToValidate}></PasswordStrengthMeter>
                                        </>
                                    </InputField>
                                    <InputField fieldName="Repeat Password" newStyle={false}>
                                        <Input type="password" name="password_again"></Input>
                                    </InputField>

                                    {passwordToValidate && confirmPassword && passwordToValidate != confirmPassword && (

                                        <p className={classNames(styles.errorText)}>
                                            Passwords do not match
                                        </p>

                                    )}
                                </>
                            )}
                        </div>)}
                    {currentWizardStep === 2 && (
                        <div>
                            <div className={styles.termsTextWrapper}>
                                <div className={styles.termsTextContent}>
                                    <h3>Fabacus Terms and Conditions including SLA v. November 2022</h3>
                                    <p>Fabacus Services Limited (no. 10123148) of 12 Soho Square - 2nd Floor, London,
                                        W1D 3QF</p>

                                    <h3>The Terms and Conditions, explained.</h3>
                                    <p>Fabacus has developed a product data management software platform, Xelacore,
                                        which enables stakeholders to input, create, manage, and share licensed product
                                        information, creating authenticated licensed products catalogues and insights
                                        and solutions to drive commercial opportunities.</p>

                                    <p>Once you’ve signed up to the terms of service, you’ll access your own dashboard
                                        to set up your profile and be provided with a solution to manage your licensed
                                        product data. You are in control of your data and who sees it. We understand
                                        legal terms can sometimes be difficult to navigate, so we wanted to give you a
                                        summary of our Agreement below.</p>

                                    <h3>WHAT ARE TERMS OF SERVICE?</h3>
                                    <p>This is a contract between you (the Client) and us (Fabacus). It describes the
                                        services we will provide to you and how we will work together. We periodically
                                        update these terms and will let you know when we do via an email or other
                                        notification.</p>

                                    <h3>WHERE CAN I FIND THE KEY TERMS?</h3>
                                    <p>The Commercial Terms you have signed outlines the main commercial terms (company
                                        names, addresses, term, and fees) as agreed with your account manager. These are
                                        also explained in Sections 1, 2, and 8 which cover the duration of the
                                        Agreement, how you subscribe to use the platform and some fundamental terms that
                                        apply to certain use cases of the platform (e.g. sharing data). You can also
                                        find information on access and acceptable use of the platform.</p>

                                    <h3>HOW IS MY DATA BEING PROTECTED?</h3>
                                    <p>Section 5 covers confidentiality and data protection, which is also covered in
                                        the Data Protection Addendum. We take the protection of your product information
                                        and your design IP extremely seriously, complying with all statutory data
                                        protection requirements. We also outline our back-up and data treatment
                                        considerations in Section 3.</p>

                                    <h3>WHAT ARE THE SUPPORT TERMS?</h3>
                                    <p>Section 1.2 covers availability & support, where we talk about how we will
                                        maintain the service for you. We’re here to support you and your business -
                                        please contact support@fabacus.com for any additional queries.</p>

                                    <h3>WHAT ARE MY RIGHTS TO CANCEL?</h3>
                                    <p>Section 8 covers termination and any end of service situations, as well as any
                                        potential warranty matters.</p>

                                    <h3>WHAT DOES THE JARGON MEAN?</h3>
                                    <p>In Section 11, you’ll find a glossary explaining the meaning of the key defined
                                        terms in the Agreement and other general items. Treat this like kind of a
                                        contractual dictionary.</p>

                                    <p>That’s it - any questions, please ask!</p>

                                </div>
                            </div>


                            <div className={styles.termsCheckbox}>
                                <label>
                                    <input onChange={checkTermsAccepted} name="terms_accepted" type="checkbox"/>
                                    <span>By creating an account, I agree to the <a target='_blank'
                                                          href="https://xelacore.zendesk.com/hc/en-gb/articles/4417717599377">Terms of Service</a></span>
                                </label>
                            </div>
                        </div>)}

                    <p className={classNames(styles.errorMessage, {[styles.hidden]: !errorMessage})}>{errorMessage}</p>
                    <div className={styles.submitButtonContainer}>
                        {currentWizardStep === 1 && (
                            <Button
                                disabled={isNextStepDisabled || passwordToValidate!=confirmPassword|| (showPass && zxcvbn(passwordToValidate).score < 3)}
                                onClick={() => {
                                    onWizardChange(2)
                                }}
                                large action type="button"
                            >
                                Continue to Terms of Service
                            </Button>)}


                        {currentWizardStep === 2 && (
                            <Button
                                disabled={!termsAccepted}
                                large action type="submit"
                            >
                                Create Account
                            </Button>)}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export const RegisterForm = memo(RegisterFormComponent);
